<template>
  <div>
    <div :class="$style['delay']">
      Recordings are logged here with delay. Please wait 2-3 minutes for your last recording to appear.
    </div>
    <div v-for="(record, sid) in callRecords" :key="sid">
      <CCallRecord :call-record="record" @play="onCallRecordPlay" @stop="onCallRecordStop" @end="onCallRecordEnd"/>
    </div>
    <div v-if="!Object.keys(callRecords).length" :class="$style['no-data']">
      No results
    </div>
  </div>
</template>

<script>
import CCallRecord from './CCallRecord.vue'
import * as pageVisibility from '../utils/pageVisibility'

const getApi = () => import('@/services/api')

export default {
  name: 'CCalls',
  components: { CCallRecord },
  data: () => {
    return {
      callRecords: {},
      audioContext: null,
      currentTrack: null,
      currentRecordPlaying: null
    }
  },
  created () {
    pageVisibility.addEventListener(this.onPageVisibilityChange)
  },
  mounted () {
    this.fetchLogs()
  },
  beforeDestroy () {
    pageVisibility.removeEventListener(this.onPageVisibilityChange)
  },
  methods: {
    async fetchLogs () {
      const api = await getApi()
      const result = await api.getRecordingLogs({
        user: this.$store.getters.getEmail,
        pass: this.$store.getters.getPassword
      })
      if (result.error) {
        this.error = result.message
        return false
      }

      this.callRecords = result.recordings.reduce((records, currentRecord) => {
        currentRecord.isPlaying = false
        records[currentRecord.log_id] = currentRecord
        return records
      }, {})
    },
    onCallRecordPlay ({ sid }) {
      this.callRecords[sid].isPlaying = true
      if (this.currentRecordPlaying !== sid) {
        if (this.currentRecordPlaying) {
          this.callRecords[this.currentRecordPlaying].isPlaying = false
        }
        this.currentRecordPlaying = sid
      }
    },
    onCallRecordStop ({ sid }) {
      this.callRecords[sid].isPlaying = false
    },
    onCallRecordEnd ({ sid }) {
      this.callRecords[sid].isPlaying = false
      this.currentRecordPlaying = null
    },
    onPageVisibilityChange (hidden) {
      if (this.currentRecordPlaying && this.callRecords[this.currentRecordPlaying].isPlaying) {
        this.callRecords[this.currentRecordPlaying].isPlaying = false
      }
    }
  }
}
</script>

<style lang="scss" module>
.no-data {
  margin-top: 20px;
  text-align: center;
  color: $primary;
}

.delay {
  margin-bottom: 15px;
}
</style>
