exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CCallRecord_call-record-container_w07lc{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row nowrap;flex-flow:row nowrap;margin-bottom:1em;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start}.CCallRecord_status-icon_276w1{color:tomato;width:32px;height:32px;margin-right:1em;margin-top:.2em}.CCallRecord_icon-warning_2hXMW{color:red}.CCallRecord_info-container_Uiwti{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-ms-flex-flow:row nowrap;flex-flow:row nowrap;-webkit-box-flex:1;-ms-flex:1 0;flex:1 0}.CCallRecord_info-name_3Ml_t{font-size:18px;font-weight:500;padding-right:8px}.CCallRecord_info-date_eUVQ-{color:grey}.CCallRecord_main-info-container_27yvk{-webkit-box-flex:1;-ms-flex:1 0;flex:1 0;flex-flow:column nowrap}.CCallRecord_main-info-container_27yvk,.CCallRecord_media-container_1AxU1{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-flow:column nowrap}.CCallRecord_media-container_1AxU1{flex-flow:column nowrap;-webkit-box-align:center;-ms-flex-align:center;align-items:center}.CCallRecord_media-icon_31eWF{width:40px;height:40px;cursor:pointer}.CCallRecord_play-icon_dpQFO,.CCallRecord_stop-icon_15J6M{color:red}.CCallRecord_info-duration_2B3E_{font-size:18px}", ""]);

// exports
exports.locals = {
	"call-record-container": "CCallRecord_call-record-container_w07lc",
	"status-icon": "CCallRecord_status-icon_276w1",
	"icon-warning": "CCallRecord_icon-warning_2hXMW",
	"info-container": "CCallRecord_info-container_Uiwti",
	"info-name": "CCallRecord_info-name_3Ml_t",
	"info-date": "CCallRecord_info-date_eUVQ-",
	"main-info-container": "CCallRecord_main-info-container_27yvk",
	"media-container": "CCallRecord_media-container_1AxU1",
	"media-icon": "CCallRecord_media-icon_31eWF",
	"play-icon": "CCallRecord_play-icon_dpQFO",
	"stop-icon": "CCallRecord_stop-icon_15J6M",
	"info-duration": "CCallRecord_info-duration_2B3E_"
};