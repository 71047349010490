<template>
  <div :class="$style['call-record-container']">
    <icon-incoming-call v-if="callRecord.status === 'ok'" :class="$style['status-icon']" />
    <icon-missed-call v-if="callRecord.status === 'no-answer' || callRecord.status === 'busy'" :class="[$style['status-icon'], $style['icon-warning']]" />
    <div :class="$style['info-container']">
      <div :class="$style['main-info-container']">
        <div :class="$style['info-name']">
          {{
            callRecord.target
          }}
        </div>
        <div :class="$style['info-date']">
          {{
            new Date(callRecord.logdate * 1000).toLocaleString()
          }}
        </div>
      </div>
      <div :class="$style['right-info-container']">
        <div v-if="callRecord.url" :class="$style['media-container']">
          <icon-stop v-if="callRecord.isPlaying" :class="[$style['media-icon'], $style['stop-icon']]" @click.native="stopAudio" />
          <icon-play v-else :class="[$style['media-icon'], $style['play-icon']]" @click.native="playAudio" />
        </div>
        <div v-else :class="$style['info-duration']">
          {{
            callRecord.status === 'ok' ? prettyDuration : '00:00'
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler'
import IconIncomingCall from '../objects/icons/IconIncomingCall.vue'
import IconMissedCall from '../objects/icons/IconMissedCall.vue'
import IconPlay from '../objects/icons/IconPlay.vue'
import IconStop from '../objects/icons/IconStop.vue'

export default {
  name: 'CCallRecord',
  components: { IconIncomingCall, IconMissedCall, IconPlay, IconStop },
  props: {
    callRecord: null
  },
  data: () => {
    return {
      recording: null
    }
  },
  computed: {
    prettyDuration () {
      let totalSeconds = this.callRecord.duration
      const hours = Math.floor(totalSeconds / 3600)
      totalSeconds %= 3600
      const minutes = Math.floor(totalSeconds / 60)
      const seconds = totalSeconds % 60

      const minutesString = String(minutes).padStart(2, '0')
      const hoursString = String(hours).padStart(2, '0')
      const secondsString = String(seconds).padStart(2, '0')
      return (hours > 0 ? hoursString + ':' : '') + minutesString + ':' + secondsString
    },
    isPlaying () {
      return this.callRecord.isPlaying
    }
  },
  watch: {
    isPlaying () {
      if (this.isPlaying) {
        this.prepare()
        this.recording.play()
      } else {
        this.recording.stop()
      }
    }
  },
  methods: {
    playAudio (event) {
      this.$emit('play', { sid: this.callRecord.log_id })
    },
    stopAudio () {
      this.$emit('stop', { sid: this.callRecord.log_id })
    },
    onAudioEnded () {
      this.$emit('end', { sid: this.callRecord.log_id })
    },
    prepare () {
      if (!this.recording) {
        this.recording = new Howl({
          src: [this.callRecord.url],
          html5: true,
          onend: this.onAudioEnded
        })
      }
    }
  }
}
</script>

<style lang="scss" module>
  .call-record-container {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 1em;
    align-items: flex-start;
  }

  .status-icon {
    color: $primary;
    width: 32px;
    height: 32px;
    margin-right: 1em;
    margin-top: 0.2em;
  }

  .icon-warning {
    color: #f00;
  }

  .info-container {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 0;
  }

  .info-name {
    font-size: 18px;
    font-weight: 500;
    padding-right: 8px;
  }

  .info-date {
    color: gray;
  }

  .main-info-container {
    display: flex;
    flex: 1 0;
    flex-flow: column nowrap;
  }

  .media-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .media-icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  .play-icon {
    color: red;
  }

  .stop-icon {
    color: red;
  }

  .info-duration {
    font-size: 18px;
  }
</style>
