let hidden

const listeners = []

const notify = () => {
  listeners.slice().forEach((listener) => {
    listener(hidden)
  })
}

function handleVisibilityChange () {
  hidden = !!document[hiddenProperty]
  notify()
}

let hiddenProperty, visibilityChangeEvent
if (global.document) {
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hiddenProperty = 'hidden'
    visibilityChangeEvent = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hiddenProperty = 'msHidden'
    visibilityChangeEvent = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hiddenProperty = 'webkitHidden'
    visibilityChangeEvent = 'webkitvisibilitychange'
  }

  if (typeof document.addEventListener !== 'undefined' && hiddenProperty !== undefined) {
    document.addEventListener(visibilityChangeEvent, handleVisibilityChange, false)
  }
}

export const getHidden = () => hidden

export const addEventListener = (listener) => {
  listeners.push(listener)
  return () => removeEventListener(listener)
}
export const removeEventListener = (listener) => {
  const idx = listeners.indexOf(listener)
  if (idx >= 0) {
    listeners.splice(idx, 1)
  }
}
