<template>
  <l-default>
    <OTitle>Recording Logs</OTitle>
    <CShareThisInline/>
    <CCalls/>
    <CAdSecondNumber />
  </l-default>
</template>

<script>
import LDefault from '../layouts/LDefault'
import OTitle from '../objects/OTitle'
import CCalls from '../components/CCalls'
import CShareThisInline from '../components/CShareThisInline'
import CAdSecondNumber from '../components/CAdSecondNumber'

export default {
  name: 'PageRecordingLogs',
  components: { OTitle, LDefault, CCalls, CShareThisInline, CAdSecondNumber }
}
</script>
