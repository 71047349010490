exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".CCalls_no-data_2lkUP{margin-top:20px;text-align:center;color:tomato}.CCalls_delay_VxtSW{margin-bottom:15px}", ""]);

// exports
exports.locals = {
	"no-data": "CCalls_no-data_2lkUP",
	"delay": "CCalls_delay_VxtSW"
};